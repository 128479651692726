<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-verification-on': this.state == 'verification',
      }"
      id="kt_login"
    >
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  <span class="text-primary">Zenofon</span> Yönetim Paneli
                </h3>
                <span class="text-muted font-weight-bold font-size-h4"
                  >Hoş geldiniz</span
                >
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Kullanıcı adı</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="text"
                    name="username"
                    ref="username"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Parola</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="password"
                    name="password"
                    ref="password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                >
                  Giriş yap
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->

          <!--begin::Verification-->
          <div class="login-form login-verification">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_verification_form"
              ref="kt_login_verification_form"
            >
              <div class="pb-13 pt-lg-0 pt-5 text-center">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Doğrulama Kodunu Girin
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ phoneNumber }} nolu numaranıza SMS ile doğrulama kodu
                  gönderildi.
                </p>
              </div>
              <div class="from-group pb-5 text-center">
                <CodeInput
                  :autoFocus="true"
                  :loading="false"
                  :fields="5"
                  class="verification-input"
                  v-on:complete="onCompleteVerificationCode"
                />
              </div>
            </form>
          </div>
          <!--end::Verification-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";

.verification-input {
  width: auto !important;
}
.verification-input input {
  font-family: "Poppins" !important;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  background-color: #ffffff;
  background-clip: padding-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 0.85rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
  height: auto !important;
  font-size: 1.5rem !important;
  border: none !important;
  border-bottom: 1px solid #3f4254 !important;
  border-radius: 0 !important;
  color: #3f4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}
.verification-input input:not(:last-child) {
  margin-right: 16px;
}
@media (max-width: 400px) {
  .verification-input input {
    width: 48px !important;
  }
}
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import CodeInput from "vue-verification-code-input";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, VERIFY_CODE } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  components: {
    CodeInput,
  },
  data() {
    return {
      state: "signin",
      phoneNumber: "",
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        username: {
          validators: {
            notEmpty: {
              message: "Kullanıcı adı boş geçilemez",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Parola boş geçilemez",
            },
          },
        },
      },
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    onSubmitLogin() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        const username = this.$refs.username.value;
        const password = this.$refs.password.value;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // send request
        this.$store
          .dispatch(LOGIN, { username, password })
          .then(function () {
            this.$router.push({ name: "dashboard" });
          })
          .catch(function (error) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire({
              title: error.data.error,
              text: error.data.errorMessage,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          });
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "Eksik bilgi girdiniz!",
          text: "Lütfen kullanıcı ad ve şifre bilgilerinizi eksiksiz girin.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },

    onCompleteVerificationCode(code) {
      // check verification code is equal to entered code
      this.$store
        .dispatch(VERIFY_CODE, code)
        .then(() => this.$router.push({ name: "dashboard" }))
        .catch(() => {
          Swal.fire({
            title: "Doğrulama kodu hatalı",
            text: "Lütfen doğrulama kodunu kontrol ederek tekrar deneyin.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
  },
};
</script>
