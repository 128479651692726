import { render, staticRenderFns } from "./CutOff.vue?vue&type=template&id=05506446"
import script from "./CutOff.vue?vue&type=script&lang=js"
export * from "./CutOff.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports