import { render, staticRenderFns } from "./AttractionsTable.vue?vue&type=template&id=6ec48b16"
import script from "./AttractionsTable.vue?vue&type=script&lang=js"
export * from "./AttractionsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports